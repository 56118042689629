<template>
  <v-dialog
    v-model="dialog"
    class="width-dialog-sm-and-down dialog-box"
    width="430"
  >
    <div class="dialog-container width-dialog-sm-and-down">
      <div class="dialog-header">
        <p>Update Payment <span class="fs-16 fw-500">({{orderList.length}} Selected)</span></p>
      </div>
      <span class="flex-sb-center total-money">
        <span>Total Paid on account</span>
        <span class="money">HKD {{amountReceived|formatMoney(2)}}</span>
      </span>
      <div
        class="dialog-body"
      >

        <div
          class="transparent">
          <select-payment
            ref="set-payment"
            :isUpdatePayment="true"
            has-border
            :approve="false"
            @getFormData="getSubFormData"
          ></select-payment>
        </div>

      </div>
      <div
        class="dialog-footer"
        align="right">
        <div class="btn-container">
          <v-button
            size="small"
            class="button-theme-block"
            @click="handleCancle">Cancel
          </v-button>
          <v-button
            size="small"
            type="primary"
            class="button-theme"
            @click="handlerValidate">Mark As Settled
          </v-button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import selectPayment from '@/views/components/selectPayment/selectPayment'
import {approvePayment, getOrderDetail, orderDownQuotation, setOrderConfirm, setOrderPayment} from '@api/order'
import MixinFormat from '@/mixins/format'
export default {
  name: 'markSettlePayment',
  components:{
    selectPayment
  },
  mixins: [MixinOptions,MixinRules,MixinFormat],
  props:{
    form:{
      type:Object,
      default:()=>({})
    },
    value:{
      type:Boolean,
      default:false
    },
    orderList:{
      type:Array,
      default:()=>([])
    },
    amountReceived:{
      type:[Number,String],
      default:0
    },
  },
  data() {
    return {
      dialog:false,
      subForm: {
        'set-payment':false,
      },
      formData:{

      }
    }
  },
  computed:{
    ...mapState('quotation', ['quoteInformationForm']),
  },
  watch:{
    value:{
      immediate:true,
      handler(val){
        this.dialog = val
      }
    }
  },
  created() {

  },
  methods: {
    handleCancle(){
      this.$emit('input',false)
    },
    getSubFormData(form, data) {
      this.subForm[form] = true
      this.formData = Object.assign(this.formData, data)
    },
    handlerValidate() {
      // 獲取表單組件數據
      let order_no = this.$route.query.order_no

      Object.keys(this.subForm).some(key => {
        this.subForm[key] = false
        this.$refs[key].submitForm(key)
      })
      if (Object.values(this.subForm).some(item => item === false)) {
        return
      }
      this.network().setOrderPayment()
    },
    network() {
      return {
        getOrderDetail: async () => {
          let order_no = this.$route.query.order_no
          let { data } = await getOrderDetail({order_no:order_no})
          let {payment}  =data.data
          if(payment&&payment.type!=6)
            this.isPayment =true
          this.formData = data.data
          this.formDataLock = 1
        },
        setOrderPayment:async (params) => {
          params = {...this.formData.payment,order_no:this.orderList}
          let { data } = await setOrderPayment(params)
          this.$emit('input',false)
          this.$emit('refresh')
        },
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-box{
  width: 430px!important;
  ::v-deep .el-dialog{
    width: 430px;
  }
  .dialog-container {
    padding: 30px;
    color: #616266;
    width: 430px;
    .dialog-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 24px;
        color: #000;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%
      }
    }
    .total-money{
      color:  #000;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      margin: 30px 0;
      .money{
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
      }
    }
    .dialog-body{
      .sent-content{
        margin: 20px 0;
        color: #718096;
        .el-checkbox{
          margin-right: 10px;
        }
      }
      .form-item{
        margin: 0 0px 30px 0px;
        span{
          color: #718096;
        }
      }
    }

    .transparent{
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
</style>
